import axios from "axios";

const baseUrl = process.env.REACT_APP_TRACK_API_URL;
const version = process.env.REACT_APP_TRACK_API_VERSION;


const api = axios.create({
	headers: {
		"X-Custom-Header": "foobar",
		"Access-Control-Allow-Credentials": true
	},
	baseURL: `${baseUrl}${version}`,
});

export {api};
