import React, {useEffect, useState} from "react";
import Application from "../../components/Application/Application";
import {getApplicationList} from "../../api/app";
import "./Home.css";

function Home() {
	const [appList, setAppList] = useState([]);

	useEffect(() => {
		getApplicationList.then((res) => setAppList(res.data)).catch((err) => setAppList([]));
	}, []);

	return (
		<div className="application-block">
			{appList.map((item) => <Application key={item.id} app={item}/>)}
		</div>
	);
}

export default Home;
