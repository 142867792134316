import React from "react";
import "./App.css";
import Home from "./pages/Home/Home";

function App() {
	const appVersion = "1.0.2";

	return (
		<div className="App">
			<header className="App-header">
				<h1>Hello there!</h1>
				<h2>Application version {appVersion}</h2>
				<Home />
			</header>
		</div>
	);
}

export default App;
