import React from "react";
import PropTypes from "prop-types";
import "./Application.css";

Application.propTypes = {
	app: PropTypes.object,
};

function Application({app}) {
	const openApp = () => window.open(app.url, "__blank");

	return (
		<div className="application" onClick={openApp}>
			<p className="application-title">{app.name}</p>
			<img className="application-image" src={app.image} alt={app.id}/>
		</div>
	);
}

export default Application;
